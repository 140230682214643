<template>
  <div>
    <v-container fluid class="fill-height">
      <v-row class="pa-0 ma-0">
        <v-col cols="12" sm="2" md="2">
          <h1><b>Tickets Cine</b></h1>
        </v-col>

        <v-col
          cols="12"
          :sm="!businessesToSearch.length ? 8 : 2"
          :md="!businessesToSearch.length ? 8 : 3"
        >
          <v-autocomplete
            v-model="businessesToSearch"
            :items="businesses"
            prepend-icon="fa-store"
            label="Elija un comercio para ver sus ventas"
            item-text="shortName"
            item-value=".key"
            @change="getTickets"
            aria-autocomplete="false"
            autocomplete="off"
            hide-details
            filled
            multiple
            rounded
            :allow-overflow="false"
            clearable
          >
            <!-- @input="adjustOptions"
            :menu-props="menuProps" -->
            <template v-slot:selection="data">
              <v-list-item-avatar
                :color="data.item.color"
                size="18"
                class="ma-0 mr-1"
              >
                <img
                  :src="data.item.logo.original"
                  :alt="data.item.shortName"
                />
              </v-list-item-avatar>
              <!-- <v-list-item-content class="pa-0">
                  <v-list-item-title>{{data.item.shortName}}</v-list-item-title>
              </v-list-item-content>-->
            </template>
            <template v-slot:item="data">
              <template>
                <v-list-item-avatar :color="data.item.color" class="mr-2">
                  <img
                    :src="data.item.logo.original"
                    :alt="data.item.shortName"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{
                    data.item.shortName
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    data.item.description
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col v-if="businessesToSearch.length > 0" cols="12" sm="3" md="3">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value="date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="date"
                label="Filtrar por fecha"
                prepend-icon="fa-calendar-alt"
                readonly
                filled
                rounded
                hide-details
                :disabled="loading"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              range
              :title-date-format="title"
              scrollable
              :max="today"
            >
              <v-row no-gutters style="flex-wrap: nowrap">
                <v-btn
                  class="flex-grow-1 flex-shrink-0"
                  large
                  rounded
                  outlined
                  color="primary"
                  @click="modal = false"
                >
                  Cancelar</v-btn
                >
                <v-btn
                  class="flex-grow-1 flex-shrink-0 ml-2"
                  large
                  rounded
                  color="primary"
                  @click="
                    $refs.dialog.save(date);
                    updateDateFilter();
                  "
                  >Filtrar</v-btn
                >
              </v-row>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col v-if="businessesToSearch.length > 0" cols="12" sm="3" md="3">
          <v-autocomplete
            v-model="tagsToSearch"
            :items="searchableTags"
            prepend-icon="fa-filter"
            label="Filtrar por estado"
            item-text="name"
            item-value="name"
            multiple
            aria-autocomplete="false"
            autocomplete="off"
            hide-details
            @change="getTickets"
            filled
            rounded
            :allow-overflow="false"
            clearable
          >
            <template v-slot:selection="data">
              <v-list-item-avatar
                :color="data.item.color"
                size="8"
                class="ma-1"
              >
                <span class="white--text headline"></span>
              </v-list-item-avatar>
            </template>
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-avatar
                  :color="data.item.color"
                  size="8"
                  class="mr-2"
                >
                  <span class="white--text headline"></span>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{
                    data.item.name | statusFilter
                  }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col
          v-if="businessesToSearch.length > 0"
          cols="12"
          sm="1"
          md="1"
          class="text-right"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                fab
                v-on="on"
                class="mr-1"
                @click="toExcel"
                dark
                :elevation="0"
                :minHeight="56"
                :loading="loading"
              >
                <v-icon x-large dark>fa-file-excel</v-icon>
              </v-btn>
            </template>
            <span>Exportar data a .CSV</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row class="pa-0 ma-0">
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="filteredOrders"
            :items-per-page="10"
            :loading="loading"
            sort-by="createdAt"
            :sort-desc="true"
            show-expand
            :single-expand="true"
            :expanded.sync="expanded"
            :no-data-text="
              businessesToSearch.length > 0
                ? 'No hay ventas para los comercios seleccionados'
                : 'Elija un comercio para ver sus ventas '
            "
            :search="search"
            item-key=".key"
            :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100, 250] }"
          >
            <template v-slot:top>
              <v-dialog
                v-model="dialogAccept"
                max-width="410px"
                :persistent="formLoading"
              >
                <v-card
                  :loading="formLoading"
                  :disabled="formLoading"
                  class="py-1"
                >
                  <v-card-title>
                    <span class="headline text-center">
                      Confirma que desea aceptar ticket
                      <b class="primary--text">{{ editedItem.shortId }}</b
                      >?
                      <div
                        class="mt-2 d-block grey--text text-center body-2"
                        style="width: 100%"
                      >
                        Ordenada de
                        <b
                          :class="
                            $vuetify.theme.dark ? 'white--text' : 'black--text'
                          "
                          >{{ editedItem.businessName }}</b
                        >
                        <br />por
                        <b
                          :class="
                            $vuetify.theme.dark ? 'white--text' : 'black--text'
                          "
                          >{{ editedItem.userName }}</b
                        >
                        {{ editedItem.progressPlacedAtRelative }}
                      </div>
                    </span>
                  </v-card-title>

                  <v-card-text>
                    <v-container class="text-center">
                      <b class="red--text title"
                        >¡Esta acción se puede revertir!</b
                      >. <br />El sistema solicitara el código de autorización
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn
                      rounded
                      class="flex-grow-1"
                      large
                      :elevation="0"
                      :minHeight="56"
                      :disabled="formLoading"
                      @click="closeAccept"
                      >Cancelar</v-btn
                    >
                    <v-btn
                      color="primary"
                      rounded
                      class="flex-grow-1 ml-2"
                      large
                      :elevation="0"
                      :minHeight="56"
                      :disabled="formLoading"
                      @click="acceptOrder"
                      >Aceptar tickets</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="dialogReverse"
                max-width="410px"
                :persistent="formLoading"
              >
                <v-card
                  :loading="formLoading"
                  :disabled="formLoading"
                  class="py-1"
                >
                  <v-card-title>
                    <span class="headline text-center">
                      Confirma que desea reversar el ticket
                      <b class="primary--text">{{ editedItem.shortId }}</b
                      >?
                      <div
                        class="mt-2 d-block grey--text text-center body-2"
                        style="width: 100%"
                      >
                        Ordenada de
                        <b
                          :class="
                            $vuetify.theme.dark ? 'white--text' : 'black--text'
                          "
                          >{{ editedItem.businessName }}</b
                        >
                        <br />por
                        <b
                          :class="
                            $vuetify.theme.dark ? 'white--text' : 'black--text'
                          "
                          >{{ editedItem.userName }}</b
                        >
                        {{ editedItem.progressPlacedAtRelative }}
                      </div>
                    </span>
                  </v-card-title>

                  <v-card-text>
                    <v-container class="text-center">
                      <label> PIN</label>
                      <v-text-field
                        full-width
                        v-model="pinCodeReverse"
                        label="PIN"
                        filled
                        rounded
                        hide-details
                        dense
                      >
                      </v-text-field>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn
                      rounded
                      class="flex-grow-1"
                      large
                      :elevation="0"
                      :minHeight="56"
                      :disabled="formLoading"
                      @click="closeAccept"
                      >Cancelar</v-btn
                    >
                    <v-btn
                      color="primary"
                      rounded
                      class="flex-grow-1 ml-2"
                      large
                      :elevation="0"
                      :minHeight="56"
                      :disabled="formLoading"
                      @click="reverseOrder"
                      >Reversar ticket</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:[`item.ticketName`]="{ item }">
              <v-chip dark small>{{ item.ticketName }} </v-chip>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                :color="getColor(item.status)"
                dark
                style="text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2)"
                >{{ item.status | statusFilter }}
              </v-chip>
            </template>
            <template v-slot:[`item.movie`]="{ item }">
              {{ item.movie.OriginalTitle }}
            </template>

            <template v-slot:[`item.price`]="{ item }">
              {{ item.price | currency }}
            </template>
            <template v-slot:[`item.seatNumber`]="{ item }">
              {{ item.seatNumber }}
            </template>

            <template v-slot:[`item.shortId`]="{ item }">
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-chip
                    :color="
                      $vuetify.theme.dark
                        ? 'rgba(0, 0, 0, 0.4)'
                        : 'rgba(0, 0, 0, 0.1)'
                    "
                    style="display: inline-block"
                    v-on="on"
                    dark
                    :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                    @click="copyToClipboard(item.shortId)"
                    >{{ item.shortId }}</v-chip
                  >
                </template>
                <i class="far fa-copy mr-2"></i>
                <span>Click para copiar</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.userName`]="{ item }">
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-chip
                    :color="
                      $vuetify.theme.dark
                        ? 'rgba(0, 0, 0, 0.4)'
                        : 'rgba(0, 0, 0, 0.1)'
                    "
                    style="display: inline-block"
                    v-on="on"
                    dark
                    :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                    @click="copyToClipboard(item.userName)"
                    >{{ item.userName | capitalize }}</v-chip
                  >
                </template>
                <i class="far fa-copy mr-2"></i>
                <span>Click para copiar</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.progressPlacedAtFiltered`]="{ item }">
              <span class>{{ item.progressPlacedAtRelative }}</span>
              <br />
              <span
                :class="
                  $vuetify.theme.dark
                    ? 'grey--text'
                    : 'grey--text text--darken-1'
                "
                >{{ item.progressPlacedAtFiltered }}</span
              >
            </template>

            <template v-slot:[`item.orderTotal`]="{ item }">
              <span class>{{ item.orderTotalExperience }}</span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <div class="actions-btn">
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="acceptItem(item)"
                      class="mx-0"
                      fab
                      v-on="on"
                      :dark="$vuetify.theme.dark"
                      x-small
                      :elevation="0"
                      color="primary"
                      :disabled="
                        item.status == 'used' || item.status == 'expired'
                      "
                    >
                      <v-icon dark>fa-check</v-icon>
                    </v-btn>
                  </template>
                  <span>Aceptar Tickets</span>
                </v-tooltip>
                <v-tooltip
                  left
                  v-if="
                    item.transferredStatus == 'available' &&
                    item.status == 'used'
                  "
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="reverseItem(item)"
                      class="ml-2"
                      fab
                      v-on="on"
                      :dark="$vuetify.theme.dark"
                      x-small
                      :elevation="0"
                      color="warning"
                    >
                      <v-icon dark>fa-solid fa-arrow-rotate-left</v-icon>
                    </v-btn>
                  </template>
                  <span>Reversar Tickets</span>
                </v-tooltip>
              </div>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="pa-4">
                <v-row>
                  <v-card
                    class="text-left pa-1 ma-1 flex-grow-1"
                    :color="
                      $vuetify.theme.dark
                        ? 'rgba(0, 0, 0, 0.4)'
                        : 'rgba(0, 0, 0, 0.1)'
                    "
                    elevation="0"
                    style="border-radius: 17px !important; overflow: hidden"
                  >
                    <v-card-text class="text--primary">
                      <p class="title ma-0" style="color: #FF1744">
                        <b> {{ item.businessName }}</b>
                      </p>
                      <p class="title ma-0" style="color: #f06a25">
                        Detalle del ticket:
                        <v-chip dark small color="#FF1744">
                          <b>{{ item.shortId }}</b>
                        </v-chip>
                      </p>
                      <v-divider></v-divider>
                      <div class="pl-2">
                        <span class="d-block font-weight-bold text--red"
                          >Nombre del ticket</span
                        >

                        <span style="d-block">{{ item.ticketName }}</span>
                        <v-divider></v-divider>
                        <span class="d-block font-weight-bold text--red"
                          >Pelicula</span
                        >
                        <span style="d-block">{{
                          item.movie.OriginalTitle
                        }}</span>
                        <v-divider></v-divider>
                        <span class="d-block font-weight-bold text--red">
                          <v-chip color="primary" outlined class="my-1">
                            {{ item.schedule.ScreenName }}</v-chip
                          >
                          <v-chip dark class="my-1 ml-2">
                            <span style="d-block">
                              Fila {{ item.seatNumber }} Asiento
                            </span>
                          </v-chip>
                        </span>
                        <v-divider></v-divider>

                        <span class="d-block font-weight-bold text--red"
                          >Raiting
                        </span>
                        <span style="d-block">{{ item.movie.Rating }}</span>

                        <v-img
                          style="
                            position: absolute;
                            border-radius: 10px;
                            right: 10px;
                            bottom: 10px;
                            border: 4px solid #d9d9d9;
                          "
                          :src="item.movie.poster"
                          width="120px"
                        ></v-img>
                      </div>
                      <template v-if="item.tinName && item.tinNumber">
                        <v-divider class="my-2"></v-divider>
                        <span class="d-block font-weight-bold text--red"
                          >Con RTN</span
                        >
                        <span style="d-block">{{ item.tinName }}</span>
                        <br />
                        <span style="d-block">{{ item.tinNumber }}</span>
                      </template>
                    </v-card-text>
                  </v-card>

                  <v-card
                    class="text-left pa-1 ma-1 flex-grow-1"
                    :color="
                      $vuetify.theme.dark
                        ? 'rgba(0, 0, 0, 0.4)'
                        : 'rgba(0, 0, 0, 0.1)'
                    "
                    elevation="0"
                    style="border-radius: 17px !important; overflow: hidden"
                  >
                    <v-card-text class="text--primary">
                      <!-- <div class="mb-n2">{{ item.shortId }}</div> -->
                      <p class="title ma-0" style="color: #FF1744">
                        <b> Detalle del Cliente:</b>
                      </p>
                      <v-divider></v-divider>
                      <span class="d-block font-weight-bold text--red"
                        >Cliente</span
                      >
                      <span style="d-block">{{ item.userName }}</span>
                      <v-divider></v-divider>
                      <span class="d-block font-weight-bold text--red"
                        >Celular</span
                      >
                      <span style="d-block">{{ item.userPhone }}</span>
                    </v-card-text>
                  </v-card>
                  <v-card
                    v-if="item.rollbackHistory"
                    class="text-left pa-1 ma-1 flex-grow-1"
                    :color="
                      $vuetify.theme.dark
                        ? 'rgba(0, 0, 0, 0.4)'
                        : 'rgba(0, 0, 0, 0.1)'
                    "
                    elevation="0"
                    style="border-radius: 17px !important; overflow: hidden"
                  >
                    <v-card-text class="text--primary">
                      <!-- <div class="mb-n2">{{ item.shortId }}</div> -->
                      <p class="title ma-0" style="color: #FF1744">
                        <b> Historial de reversión:</b>
                      </p>
                      <v-divider></v-divider>
                      <v-row
                        v-for="(i, index) in item.rollbackHistory"
                        :key="index"
                        class="pa-0"
                        style="border-bottom: 1px #d1d1d1 solid"
                      >
                        <v-col class="flex-start" cols="4">
                          <span class="d-block font-weight-bold text--red"
                            >Usado</span
                          >
                          <span style="d-block">{{
                            i.usedAt | formatFullDate
                          }}</span>
                        </v-col>
                        <v-col class="flex-end flex-colums" cols="4">
                          <span class="d-block font-weight-bold text--red"
                            >Reversión</span
                          >
                          <span style="d-block">{{
                            i.date | formatFullDate
                          }}</span>
                        </v-col>
                        <v-col cols="4" v-if="i.authorizingUserName">
                          <span class="d-block font-weight-bold text--red"
                            >Autorización</span
                          >
                          <span style="d-block">{{
                            i.authorizingUserName
                          }}</span>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-row>
              </td>
            </template>

            <template v-slot:loading>
              <div class="ma-5" style="position: relative">
                <div
                  style="
                    position: absolute;
                    z-index: 999;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <lottie
                    :options="defaultOptions"
                    :height="300"
                    :width="400"
                    v-on:animCreated="handleAnimation"
                  />
                  <p
                    :class="
                      $vuetify.theme.dark
                        ? 'subtitle-1 primary--text'
                        : 'subtitle-1 black--text'
                    "
                  >
                    Cargando Data
                  </p>
                </div>
                <v-skeleton-loader
                  ref="skeleton"
                  type="table-tbody"
                  class="mx-auto d-none d-md-block"
                >
                </v-skeleton-loader>
              </div>
            </template>

            <template v-slot:[`body.append`]>
              <tr>
                <td colspan="12">
                  <v-container>
                    <v-row>
                      <v-col cols="10" sm="11" md="11">
                        <!-- <v-row justify="center"> -->
                        <span style="font-weight: bold; font-size: 14px"
                          >Total de órdenes completadas</span
                        >
                        <!-- </v-row> -->
                      </v-col>
                      <v-col cols="2" sm="1" md="1">
                        <span style="font-weight: bold; font-size: 14px">{{
                          totalAmount
                        }}</span>
                      </v-col>
                    </v-row>
                  </v-container>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar
      absolute
      top
      style="z-index: 1000"
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import { db, fb } from "@/firebase";
import Lottie from "@/components/Lottie";
import * as animationData from "@/assets/ochoColor.json";
import { Parser } from "json2csv";
import JSEncrypt from "nodejs-jsencrypt";
import { mapActions, mapState } from "vuex";
// import * as moment from "moment";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default {
  name: "Tickets-Theatres",
  components: {
    // HelloWorld
    lottie: Lottie,
  },
  data() {
    return {
      modal: null,
      date: [
        // inicio de mes
        `${new Date(new Date().valueOf() - 1000 * 60 * 60 * 24)
          .toISOString()
          .substr(0, 10)}`,
        // Ayer
        // `${moment()
        //   // .startOf("day")
        //   .subtract(1, "days")
        //   .toDate()
        //   .toISOString()
        //   .substr(0, 10)}`,
        // Hoy
        // `${moment()
        // // .startOf("day")
        // .toDate()
        // .toISOString()
        // .substr(0, 10)}`,
        `${new Date().toISOString().substr(0, 10)}`,
        // `${new Date().getFullYear()}-${new Date().getMonth() + 1}-01`,
        // `${new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().substr(0, 10)}`
        // `${new Date().toISOString().substr(0, 10)}`
      ],
      dialogReverse: false,
      agent: null,
      agents: [],
      anim: null,
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      typeQuery: "",
      dialog: false,
      pinCodeReverse: null,
      dialogAccept: false,
      snackbar: false,
      snackbarText: null,
      loading: true,
      formLoading: false,
      businesses: [],
      orders: [],
      ordersExpirence: [],
      menuProps: {},
      expanded: [],
      itemsPerPageOptions: [4, 8, 12],
      itemsPerPage: 4,
      tagsToSearch: [],
      businessesToSearch: [],
      maxSelected: 10,
      defaultItem: {},
      editedItem: {},
      searchableTags: [
        { name: "available", color: "info" },
        { name: "used", color: "red" },
      ],
      headers: [
        {
          text: "Estado",
          value: "status",
          align: "center",
        },
        {
          text: "Order ID",
          value: "shortId",
          align: "center",
        },
        {
          text: "Peliulas",
          value: "movie",
        },
        {
          text: "Butaca",
          value: "seatNumber",
        },
        {
          text: "Nombre de entrada",
          value: "ticketName",
        },
        {
          text: "Usuario",
          value: "userName",
        },
        {
          text: "Negocio",
          value: "businessName",
        },
        {
          text: "Monto Total",
          value: "price",
          align: "end",
        },
        {
          text: "Creada",
          value: "progressPlacedAtFiltered",
          filterable: false,
        },

        { value: "actions", width: "64" },
      ],
    };
  },
  filters: {
    formatFullDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("DD/MM/YYYY hh:mm A");
    },
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
      });
      return `L ${formatter.format(amount)}`;
    },
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      var splitStr = value.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return " " + splitStr.join(" ");
    },
    statusFilter: function (status) {
      switch (status) {
        case "available":
          return "Disponible";
          break;

        case "used":
          return "Usado";
          break;
        case "expired":
          return "Expirado";
          break;

        default:
          return "Desconocido";
          break;
      }
    },

    tsToDate: function (timestamp) {
      if (!timestamp) return "n/a";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("DD/MM/YYYY, HH:mm:ss [GMT]Z");
    },
    tsToTime: function (timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("HH:mm:ss:SSS");
    },
  },
  computed: {
    ...mapState(["user"]),
    // computedCounterValue() {
    //   let totalCount = 0;
    //   if (this.businessesToSearch && this.businessesToSearch.length > 0) {
    //     const selectedItems = this.businessesToSearch.map((id) => {
    //       return id;
    //     });

    //     totalCount = selectedItems.reduce(function (prev, cur) {
    //       return prev + (cur.count ? cur.count : 1);
    //     }, 0);
    //   }
    //   return totalCount;
    // },
    totalAmount: function () {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });

      var sum = 0;
      this.filteredOrders.forEach((e) => {
        sum += e.price;
      });
      return formatter.format(sum);
    },

    search() {
      return this.$store.state.search;
    },
    yesterday() {
      var date = new Date();
      date.setDate(date.getDate() - 1);
      return date.toISOString().substr(0, 10);
    },

    tomorrow() {
      let tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow.toISOString().substr(0, 10);
    },
    today() {
      return new Date().toISOString().substr(0, 10);
    },
    TimeActual() {
      return new Date();
    },
    todayTime() {
      return new Date().toISOString().substr(0, 10);
    },
    formattedDate() {
      return this.date ? moment(this.date).format("dddd, MMMM Do YYYY") : "";
    },
    filteredOrders() {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      let filter = this.orders.map((x) => {
        x.progressPlacedAtFiltered = x.createdAt
          ? moment(x.createdAt.toDate())
              .tz("America/Tegucigalpa")
              .format("DD/MM, hh:mm A")
          : "";

        var dateExpTicket = moment(x.schedule.ScheduleDate, "YYYYMMDD").format(
          "YYYY-MM-DD"
        );
        var timeExpTicket = moment(x.schedule.StartTime, "HH:mm")
          .add(parseInt(x.movie.TotalRuntime), "minutes")
          .format("HH:mm");

        var timeActual = moment(this.TimeActual, "HH:mm")
          .tz("America/Tegucigalpa")
          .format("DD/MM/YY, hh:mm A");

        var dateExpiredTicket = moment(
          dateExpTicket + "," + timeExpTicket,
          "YYYYMMDD,HH:mm"
        )
          .tz("America/Tegucigalpa")
          .format("DD/MM/YY, hh:mm A");
        x.dateExpiredTicket = dateExpiredTicket;

        if (x.status == "available" && timeActual >= dateExpiredTicket) {
          x.status = "expired";
        }
        return x;
      });

      if (this.tagsToSearch.length > 0) {
        filter = filter.filter((order) => {
          return this.tagsToSearch.includes(order.status);
        });
      }

      return filter;
    },
  },
  methods: {
    log() {
      console.log(this.businesses.find(() => ".key" === row.businessId));
    },

    RSAencrypt(d) {
      let jse = new JSEncrypt();
      let publicKey = process.env.VUE_APP_PUBLIC_KEY;
      jse.setPublicKey(publicKey);
      return jse.encrypt(JSON.stringify(d));
    },

    async getTickets() {
      console.debug(this.businessesToSearch);
      if (!this.businessesToSearch.length) {
        this.orders = [];
        return;
      }
      console.debug(this.businessesToSearch);
      this.loading = true;
      this.$store.commit("setSearch", "");
      this.orders = [];

      let query = db
        .collection(`tickets`)
        .where(
          "createdAt",
          ">",
          moment(this.date[0], "YYYY-MM-DD").startOf("day").toDate()
        )
        .where(
          "createdAt",
          "<",
          moment(this.date[1], "YYYY-MM-DD").endOf("day").toDate()
        );

      if (this.user.type == "businessOwner") {
        query = query
          .where("businessId", "in", this.businessesToSearch.slice(0, 10))
          .where("type", "==", "cine");

        await this.$binding("orders", query);
        this.loading = false;
      }
    },

    toExcel() {
      const filename = "data.csv";
      const fields = [
        {
          label: "Estado",
          value: "status",
        },
        {
          label: "ID de Orden",
          value: "shortId",
        },
        {
          label: "Comercio",
          value: (row) => {
            try {
              let getId = (business) => {
                return business[".key"] === row.businessId;
              };
              return `${this.businesses.find(getId).shortName}`;
            } catch (error) {
              return `No disponible, verificar con un administrador`;
            }
          },
        },
        {
          label: "Cliente",
          value: "userName",
        },
        {
          label: "Pelicula",
          value: (row) => {
            try {
              let getId = (movie) => {
                return row.movie.OriginalTitle;
              };
              return `${row.movie.OriginalTitle}`;
            } catch (error) {
              return `No disponible, verificar con un administrador`;
            }
          },
        },
        {
          label: "Butaca",
          value: "seatNumber",
        },
        {
          label: "Sala",

          value: (row) => {
            try {
              return row.schedule.ScreenName.replace('"', "");
            } catch (error) {
              return `No disponible`;
            }
          },
        },
        {
          label: "Nombre de entrada",
          value: (row) => {
            try {
              return row.ticketName.replace('"', "");
            } catch (error) {
              return `No disponible`;
            }
          },
        },
        {
          label: "Monto Total",
          value: "price",
        },
        {
          label: "Fecha de Creación",
          value: "progressPlacedAtFiltered",
        },
      ];

      const json2csvParser = new Parser({
        fields,
        excelStrings: true,
        withBOM: true,
      });
      const csv = json2csvParser.parse(this.filteredOrders);

      let blob = new Blob([csv], { type: "text/csv" });
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        var elem = window.document.createElement("a");
        elem.href = window.URL.createObjectURL(blob);
        elem.download = filename;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      }
    },
    acceptItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogAccept = true;
      this.formLoading = true;
      this.agent = null;
      this.formLoading = false;
    },
    reverseItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogReverse = true;
      this.formLoading = true;
      this.agent = null;
      this.formLoading = false;
    },
    processItem(item) {
      this.snackbar = true;
      this.snackbarText = `No implementado aún 😕`;
    },
    availableAgents(item) {
      return item.is_available === 0 ? true : false;
    },
    getTicketsList(row) {
      let itemList = "";

      row.products.forEach((product, index) => {
        itemList += `${product.quantity}x ${product.name} ${
          index < row.products.length - 1 ? ", " : ""
        }`;
      });

      return itemList;
    },
    closeAccept() {
      this.dialogAccept = false;
      this.dialogReverse = false;
      this.formLoading = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.agent = null;
      });
    },
    getSectionToken() {
      return new Promise((resolve, reject) => {
        fb.auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            resolve(token);
          });
      });
    },
    async acceptOrder() {
      this.formLoading = true;
      const item = this.editedItem;
      console.debug(item);

      let data = {
        ticketId: item[`.key`],
      };
      console.debug(data);
      fb.functions()
        .httpsCallable("httpThirdPartyProvidersCinestarBurnTicket")(data)
        .then(() => {
          this.snackbar = true;
          this.snackbarText = `Orden ${this.editedItem.shortId} aceptada con éxito 👍`;
          this.closeAccept();

          return true;
        })
        .catch((err) => {
          this.dialogAccept = false;
          this.formLoading = false;
          setTimeout(() => {
            this.snackbar = true;
            this.snackbarText = err.message
              ? err.message
              : `Error al aceptar Orden ${this.editedItem.shortId} 😢`;
          }, 500);
        });
    },
    async reverseOrder() {
      if (this.pinCodeReverse && this.editedItem) {
        this.formLoading = true;
        const item = this.editedItem;
        let datos = {
          ticketId: item[`.key`],
          businessId: item.businessId,
          code: this.pinCodeReverse,
        };
        let data = {
          request: await this.RSAencrypt(datos),
        };

        fb.functions()
          .httpsCallable("httpThirdPartyProvidersCinestarRollBackTickets")(data)
          .then(() => {
            this.snackbar = true;
            this.snackbarText = `Ticket ${this.editedItem.shortId} reversado con éxito 👍`;
            this.closeAccept();
            this.pinCodeReverse = null;
            return true;
          })
          .catch((err) => {
            this.dialogAccept = false;
            this.formLoading = false;
            setTimeout(() => {
              this.snackbar = true;
              this.snackbarText = err.message
                ? err.message
                : `Error al reversar ticket ${this.editedItem.shortId} 😢`;
            }, 500);
          });
      } else {
        this.snackbar = true;
        this.snackbarText = `Ingresa el PIN`;
      }
    },
    title() {
      return "Selecciona las fechas a filtrar";
    },
    handleAnimation(anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    getColor(status) {
      const result = this.searchableTags.find(({ name }) => name === status);
      if (result) {
        return result.color;
      } else {
        return "gray";
      }
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.snackbar = true;
          this.snackbarText = "Copiado al portapapeles";
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
    updateDateFilter() {
      if (this.date.length > 1) {
        if (moment(this.date[0], "YYYY-MM-DD") > new Date(this.date[1])) {
          this.date = [this.date[1], this.date[0]];
        }
      } else {
        this.date = [this.date[0], this.date[0]];
      }
    },
    remove(item) {
      const index = this.tagsToSearch.indexOf(item.name);
      if (index >= 0) this.tagsToSearch.splice(index, 1);
    },

    getBusinesses() {
      let arrayCopy = Object.assign([], this.user.businesses);

      arrayCopy.forEach((id, index) => {
        if (index % 10 == 0) {
          let array = arrayCopy.slice(index, index + 10);
          // theatreGroupId
          db.collection(`businesses`)
            .where(fb.firestore.FieldPath.documentId(), "in", array)
            .get()
            .then((businesses) => {
              this.loading = false;
              businesses.forEach((business) => {
                let item = business.data();
                item[".key"] = business.id;
                if (item.theatreGroupId) {
                  this.businesses.push(item);
                }
              });
            });
        }
      });
    },
  },
  destroyed() {
    this.$store.commit("setSearchTerm", "");
    this.$store.commit("setSearch", "");
  },
  mounted() {
    switch (this.user.type) {
      case "sudo":
        this.$router.push({ path: "/orders/administration" });
        break;
      case "superSac":
        this.$router.push({ path: "/orders/administration" });
        break;
      case "businessOwner":
        // if (this.$router.history.current.fullPath != "/orders")
        //   this.$router.push({ path: "/orders" });

        break;
      default:
        this.$router.push({ path: "/" });
        break;
    }

    this.getBusinesses();

    this.loading = true;
    this.$store.commit("setSearchTerm", "Ventas");
    this.$store.commit("setSearch", "");
  },
  watch: {
    dialog(val) {
      val || this.close();
    },

    date(val) {
      if (val.length > 1) {
        this.getTickets();
      }
    },
    typeQuery(val) {
      if (val == "tickets") {
      }
      if (val == "orders") {
      }
    },
  },
};
</script>
<style>
.actions-btn {
  display: flex;
  flex-direction: row !important;
}

.flex-colums {
  flex-direction: column;
}
tr {
  white-space: nowrap !important;
}
td {
  white-space: nowrap !important;
}
</style>
